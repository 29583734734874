import React, { PureComponent, lazy } from 'react';
import { Route, Switch } from "react-router-dom";
import {routes} from "../routes";
import { connect } from 'react-redux';
type Routes = typeof routes;


interface OwnProps {}

type Props = OwnProps & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

type State = Readonly<{}>;


class BasicRouteLayout extends PureComponent<Props, State> {
    readonly state: State = {};

    public mapRoutes(routes: Routes): any {
        return routes.map(({redirect, children, ...props }, index) => {
            // if (redirect) return <Redirect path={props.path} strict exact key={index} to={redirect} {...props} />;
            if (props.component) {
                const Page = lazy(() => import(`../pages${props.component}`));
                return <Route exact={!Boolean(children)} key={index} {...props} component={(p: any) => {
                    if (!children) return <Page {...p} />;
                    return (
                        <Page menu={children} { ...p } >
                            <Switch>
                                {this.mapRoutes(children)}
                            </Switch>
                        </Page>
                    )
                }} />
            } else if (children) {
                return this.mapRoutes(children);
            }
            return null;
        })
    }

    public render() {
        return (
            <Switch>
                {this.mapRoutes(routes)}
            </Switch>
        )
    }
}
const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(BasicRouteLayout);
