import thunkMiddleware from 'redux-thunk';
import reduxLogger from 'redux-logger';
import {createStore, combineReducers, Middleware, compose, applyMiddleware} from 'redux';
import {AdminAction} from "./actions/admin.action";
import {adminReducer, AdminState} from "./reducers/admin.reducer";

const middleware: Middleware[] = [
    thunkMiddleware
];

if (process.env.NODE_ENV === 'development') {
    middleware.push(reduxLogger)
}

const store = createStore<IStore, AdminAction, {}, {}>(
    combineReducers({
        admin: adminReducer
    }),
    compose(
        applyMiddleware(...middleware)
    )
);

export interface IStore {
    admin: AdminState
}

export default store;