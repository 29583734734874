import React, { PureComponent } from 'react';
import "./BasicLayout.less";

interface OwnProps {}

type Props = OwnProps;

type State = Readonly<{}>;

class BasicLayout extends PureComponent<Props, State> {
  readonly state: State = {};

  render() {
    return (
      <div className="basic-layout">
        {this.props.children}
      </div>
    );
  }
}

export default BasicLayout;
