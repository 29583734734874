import {AdminAction} from "../actions/admin.action";
import {ActionEnum} from "../../constants/action.enum";
import {AdminTheme, User} from "../../interface";

const theme = localStorage.getItem("theme") as AdminTheme;

const initState: AdminState = {
    theme: theme || 'dark',
    user: null,
};
export function adminReducer(state = initState, action: AdminAction): AdminState {
    switch (action.type) {
        case ActionEnum.login:
            return {
                ...state,
                user: action.data,
            };
        case ActionEnum.theme:
            return  {
                ...state,
                theme: action.theme
            };
        default:
            return state;
    }
}

export interface AdminState {
    theme: AdminTheme;
    user: User | null;
}
