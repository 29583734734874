import './index.css';
import "normalize.css/normalize.css";
import React, { Suspense } from 'react';
import ReactDOM  from 'react-dom';
import { Provider } from 'react-redux';
import store from "./store";
import {ConfigProvider, Spin} from 'antd';
import zhCN from 'antd/lib/locale-provider/zh_CN';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter} from "react-router-dom";
import BasicLayout from "./layouts/BasicLayout";
import BasicRouteLayout from "./layouts/BasicRouteLayout";

ReactDOM.render(
    <BasicLayout>
        <Provider store={store}>
            <ConfigProvider locale={zhCN}>
                <BrowserRouter>
                    <Suspense
                        fallback={<div style={{
                            width: "100vw",
                            height: "100vh",
                            position: "fixed",
                            top: 0,
                            left: 0,
                            zIndex: 999,
                            background: "rgba(0,0,0, 0.2)",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center"
                        }}
                        >
                            <Spin />
                        </div>}>
                        <BasicRouteLayout />
                    </Suspense>
                </BrowserRouter>
            </ConfigProvider>
        </Provider>
    </BasicLayout>
    ,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
