export const routes: RouteMenu[] = [
    {
        path: "/",
        component: "/index"
    },
    {
        path: "/car/readme/:id",
        component: "/readme",
    },
    {
        path: "/car/note/:id",
        component: "/note",
    },
    {
        path: "/car/video/:id",
        component: "/video",
    },
    {
        path: "/car/record/:id",
        component: "/record",
    },
    {
        path: "/car/submit/:id",
        component: "/submit",
    },
    {
        path: "/car/:id",
        component: "/detail",
    },
    {
        path: "/admin",
        component: "/admin/",
        children: [
            {
                path: "/admin/",
                name: "欢迎",
                icon: "dashboard",
                component: "/admin/Welcome"
            },
            {
                path: "/admin/car",
                name: "车辆管理",
                icon: "reconciliation",
                children: [
                    {
                        path: "/admin/car/list",
                        name: "车辆列表",
                        icon: "menu",
                        component: "/admin/car/list"
                    },
                    {
                        path: "/admin/car/save",
                        name: "新增车辆",
                        icon: "plus",
                        component: "/admin/car/form"
                    },
                    {
                        path: "/admin/car/update/:id",
                        name: "更新车辆",
                        icon: "edit",
                        component: "/admin/car/form",
                        hide: true
                    },
                ]
            },
            {
                path: "/admin/setting",
                name: "系统设置",
                icon: "setting",
                component: "/admin/setting/User"
                // children: [
                //     {
                //         path: "/admin/setting/user",
                //         name: "账号设置",
                //         icon: "user",
                //         component: "/admin/setting/User"
                //     },
                //     {
                //         path: "/admin/setting/mine",
                //         name: "个人设置",
                //         icon: "control",
                //         component: "/admin/setting/Mine"
                //     }
                // ]
            },
            {
                path: "*",
                component: "/NotFound"
            }
        ]
    },
    {
        path: "/404.html",
        component: "/NotFound"
    },
    {
        path: "*",
        component: "/NotFound"
    }
];

export interface RouteMenu {
    path?: string;
    component?: string;
    redirect?: string;
    name?: string;
    icon?: string;
    children?: RouteMenu[];
    hide?: boolean;
}
